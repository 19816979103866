import { Fragment } from 'react';
import { Link } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from '../../../../Helper';
import Logo from '../../../../images/logo.png';

function MobileNavSlider(props)
{	
	const uploaded_logo = 'https://scrm.brawur.com/cache/logo.svg';

	/**
	 * Close the modal and update the current nav
	 * 
	 * @param {string} module_name 
	 */
	function updateCurrentNav(module_name) {
		props.setSidebarOpen(false);
		props.updateCurrentNav(module_name);
	}

    return (
        <Transition.Root show={props.sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed inset-0 flex z-40 lg:hidden"
              open={props.sidebarOpen}
              onClose={props.setSidebarOpen}
            >
            	<Transition.Child
                	as={Fragment}
                	enter="transition-opacity ease-linear duration-300"
                	enterFrom="opacity-0"
                	enterTo="opacity-100"
                	leave="transition-opacity ease-linear duration-300"
                	leaveFrom="opacity-100"
                	leaveTo="opacity-0"
              	>
                	<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              	</Transition.Child>
              	<Transition.Child
                	as={Fragment}
                	enter="transition ease-in-out duration-300 transform"
                	enterFrom="-translate-x-full"
                	enterTo="translate-x-0"
                	leave="transition ease-in-out duration-300 transform"
                	leaveFrom="translate-x-0"
                	leaveTo="-translate-x-full"
              	>
                	<div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                  		<Transition.Child
                			as={Fragment}
                	    	enter="ease-in-out duration-300"
                    		enterFrom="opacity-0"
                    		enterTo="opacity-100"
                    		leave="ease-in-out duration-300"
                    		leaveFrom="opacity-100"
                    		leaveTo="opacity-0"
                  		>
                    		<div className="absolute top-0 right-0 -mr-12 pt-2">
                      			<button
                        			className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        			onClick={() => props.setSidebarOpen(false)}
                      			>
                        			<span className="sr-only">Close sidebar</span>
                        			<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      			</button>
                    		</div>
                  		</Transition.Child>
                  		<div className="flex-shrink-0 flex items-center px-4">
                    		<img
                      			className="h-8 w-auto"
                      			src={uploaded_logo ? uploaded_logo : Logo}
                      			alt="Logo"
                    		/>
                  		</div>
                  		<div className="mt-5 flex-1 h-0 overflow-y-auto">
                    		<nav className="px-2">
                      			<div className="space-y-1">
                        			{props.navigation.map((item) => (
                          				<Link
                            				key={item.name}
                            				to={item.href}
											onClick={() => updateCurrentNav(item.name)}
                            				className={classNames(
                            					item.current
                              					? 'bg-gray-100 text-gray-900'
                              					: 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                              					'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                            				)}
                            				aria-current={item.current ? 'page' : undefined}
                          				>
                            				<item.icon
                              					className={classNames(
                                					item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                					'mr-3 flex-shrink-0 h-6 w-6'
                              					)}
                            	  				aria-hidden="true"
                            				/>
                            				{item.name}
                          				</Link>
                        			))}
                      			</div>
                    		</nav>
                  		</div>
                	</div>
              	</Transition.Child>
              	<div className="flex-shrink-0 w-14" aria-hidden="true"></div>
            </Dialog>
        </Transition.Root>
    )
}

export default MobileNavSlider;
